.single {
  text-align: center;
  &__title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 22px;
    padding: 1rem;
  }

  &__desc {
    color: var(--app-text-60-color);
    font-size: 18px;
    margin-bottom: 59px;
    padding: 1rem;
  }

  &__List {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 32px;
    padding: 1rem;
    overflow: auto;
    &--item {
      width: 230px;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      height: 120px;
      border: 1px solid;
      border-radius: 10px;
      padding: 16px 20px;
      cursor: pointer;
      background-position: 100%;
      background-size: cover;
      color: #fff;
      &:hover {
        .model {
          display: flex;
        }
        .title {
          display: none;
        }
      }
      .model {
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        span {
          font-size: 16px;
          color: #fff;
        }
        .divider {
          background: #fff;
        }
      }
      .title {
        text-align: left;
      }

      &:last-child {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: url('https://res-front.pumpsoul.com/pb-prod/image/feedbackBackground.webp') 100% 100%;
        background-size: cover;
        padding: 14px;

        .icon {
          text-align: end;
          color: #fff;
        }

        .desc {
          width: 100%;
          color: #fff;
          font-size: 14px;
          align-self: flex-start;
          text-align: left;

          .descTitle {
            font-weight: 700;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .single {
    text-align: center;
    &__title {
      font-size: 24px;
    }
    &__desc {
      color: var(--app-text-60-color);
      font-size: 18px;
      margin-bottom: 50px;
      padding: 1rem;
    }

    &__List {
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

