.model {
  z-index: 1000;
  padding: 44px 24px;
  border-radius: 12px;
  //   width: 421px;
  overflow: hidden;

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .desc {
    color: var(--app-text-30-color);
    margin-bottom: 20px;
  }

  .textarea {
    padding: 16px;
    resize: none;
    min-height: 148px;
    margin-bottom: 44px;
  }

  .btnContent {
    text-align: right;
  }
}

